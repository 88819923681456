/* eslint-disable i18next/no-literal-string */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"
import { useTheme } from "@mui/material/styles"

function NoDataIcon() {
    const theme = useTheme()
    return (
        <svg
            height={23}
            viewBox="0 0 820.16 780.81"
            width={23}>
            <defs>
                <linearGradient
                    gradientTransform="translate(-3.62 1.57)"
                    gradientUnits="userSpaceOnUse"
                    id="07332201-7176-49c2-9908-6dc4a39c4716"
                    x1="539.63"
                    x2="539.63"
                    y1="734.6"
                    y2="151.19">
                    <stop
                        offset="0"
                        stopColor="gray"
                        stopOpacity="0.25"
                    />
                    <stop
                        offset="0.54"
                        stopColor="gray"
                        stopOpacity="0.12"
                    />
                    <stop
                        offset="1"
                        stopColor="gray"
                        stopOpacity="0.1"
                    />
                </linearGradient>
                <linearGradient
                    gradientTransform="translate(-63.92 7.85)"
                    id="0ee1ab3f-7ba2-4205-9d4a-9606ad702253"
                    x1="540.17"
                    x2="540.17"
                    y1="180.2"
                    y2="130.75"
                />
                <linearGradient
                    gradientTransform="translate(-84.51 124.6) rotate(-12.11)"
                    id="abca9755-bed1-4a97-b027-7f02ee3ffa09"
                    x1="540.17"
                    x2="540.17"
                    y1="140.86"
                    y2="82.43"
                />
                <linearGradient
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    id="2632d424-e666-4ee4-9508-a494957e14ab"
                    x1="476.4"
                    x2="476.4"
                    y1="710.53"
                    y2="127.12"
                />
                <linearGradient
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    id="97571ef7-1c83-4e06-b701-c2e47e77dca3"
                    x1="476.94"
                    x2="476.94"
                    y1="156.13"
                    y2="106.68"
                />
                <linearGradient
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    id="7d32e13e-a0c7-49c4-af0e-066a2f8cb76e"
                    x1="666.86"
                    x2="666.86"
                    y1="176.39"
                    y2="117.95"
                />
            </defs>
            <title>{"no data"}</title>
            <rect
                fill="#e0e0e0"
                height="603.82"
                transform="translate(-271.22 62.72) rotate(-12.11)"
                width="437.02"
                x="317.5"
                y="142.55"
            />
            <g opacity="0.5">
                <rect
                    fill="url(#07332201-7176-49c2-9908-6dc4a39c4716)"
                    height="583.41"
                    transform="translate(-271.22 62.72) rotate(-12.11)"
                    width="422.25"
                    x="324.89"
                    y="152.76"
                />
            </g>
            <rect
                fill="#fafafa"
                height="570.52"
                transform="translate(-270.79 62.58) rotate(-12.11)"
                width="411.5"
                x="329.81"
                y="157.1"
            />
            <rect
                fill="url(#0ee1ab3f-7ba2-4205-9d4a-9606ad702253)"
                height="49.45"
                transform="translate(-213.58 43.93) rotate(-12.11)"
                width="204.14"
                x="374.18"
                y="138.6"
            />
            <path
                d="M460.93,91.9c-15.41,3.31-25.16,18.78-21.77,34.55s18.62,25.89,34,22.58,25.16-18.78,21.77-34.55S476.34,88.59,460.93,91.9ZM470.6,137A16.86,16.86,0,1,1,483.16,117,16.66,16.66,0,0,1,470.6,137Z"
                fill="url(#abca9755-bed1-4a97-b027-7f02ee3ffa09)"
                transform="translate(-189.92 -59.59)"
            />
            <rect
                fill={theme.palette.secondary.main}
                height="47.27"
                transform="translate(-212.94 43.72) rotate(-12.11)"
                width="199.84"
                x="375.66"
                y="136.55"
            />
            <path
                d="M460.93,91.9a27.93,27.93,0,1,0,33.17,21.45A27.93,27.93,0,0,0,460.93,91.9ZM470.17,135a16.12,16.12,0,1,1,12.38-19.14A16.12,16.12,0,0,1,470.17,135Z"
                fill={theme.palette.secondary.main}
                transform="translate(-189.92 -59.59)"
            />
            <rect
                fill="#e0e0e0"
                height="603.82"
                width="437.02"
                x="257.89"
                y="116.91"
            />
            <g opacity="0.5">
                <rect
                    fill="url(#2632d424-e666-4ee4-9508-a494957e14ab)"
                    height="583.41"
                    width="422.25"
                    x="265.28"
                    y="127.12"
                />
            </g>
            <rect
                fill="#fff"
                height="570.52"
                width="411.5"
                x="270.65"
                y="131.42"
            />
            <rect
                fill="url(#97571ef7-1c83-4e06-b701-c2e47e77dca3)"
                height="49.45"
                width="204.14"
                x="374.87"
                y="106.68"
            />
            <path
                d="M666.86,118c-15.76,0-28.54,13.08-28.54,29.22s12.78,29.22,28.54,29.22,28.54-13.08,28.54-29.22S682.62,118,666.86,118Zm0,46.08a16.86,16.86,0,1,1,16.46-16.86A16.66,16.66,0,0,1,666.86,164Z"
                fill="url(#7d32e13e-a0c7-49c4-af0e-066a2f8cb76e)"
                transform="translate(-189.92 -59.59)"
            />
            <rect
                fill={theme.palette.secondary.main}
                height="47.27"
                width="199.84"
                x="377.02"
                y="104.56"
            />
            <path
                d="M666.86,118a27.93,27.93,0,1,0,27.93,27.93A27.93,27.93,0,0,0,666.86,118Zm0,44.05A16.12,16.12,0,1,1,683,145.89,16.12,16.12,0,0,1,666.86,162Z"
                fill={theme.palette.secondary.main}
                transform="translate(-189.92 -59.59)"
            />
            <g opacity="0.5">
                <rect
                    fill="#47e6b1"
                    height="21.33"
                    width="3.76"
                    x="15.27"
                    y="737.05"
                />
                <rect
                    fill="#47e6b1"
                    height="21.33"
                    transform="translate(824.47 540.65) rotate(90)"
                    width="3.76"
                    x="205.19"
                    y="796.65"
                />
            </g>
            <g opacity="0.5">
                <rect
                    fill="#47e6b1"
                    height="21.33"
                    width="3.76"
                    x="451.49"
                />
                <rect
                    fill="#47e6b1"
                    height="21.33"
                    transform="translate(523.63 -632.62) rotate(90)"
                    width="3.76"
                    x="641.4"
                    y="59.59"
                />
            </g>
            <path
                d="M961,832.15a4.61,4.61,0,0,1-2.57-5.57,2.22,2.22,0,0,0,.1-.51h0a2.31,2.31,0,0,0-4.15-1.53h0a2.22,2.22,0,0,0-.26.45,4.61,4.61,0,0,1-5.57,2.57,2.22,2.22,0,0,0-.51-.1h0a2.31,2.31,0,0,0-1.53,4.15h0a2.22,2.22,0,0,0,.45.26,4.61,4.61,0,0,1,2.57,5.57,2.22,2.22,0,0,0-.1.51h0a2.31,2.31,0,0,0,4.15,1.53h0a2.22,2.22,0,0,0,.26-.45,4.61,4.61,0,0,1,5.57-2.57,2.22,2.22,0,0,0,.51.1h0a2.31,2.31,0,0,0,1.53-4.15h0A2.22,2.22,0,0,0,961,832.15Z"
                fill="#4d8af0"
                opacity="0.5"
                transform="translate(-189.92 -59.59)"
            />
            <path
                d="M326.59,627.09a4.61,4.61,0,0,1-2.57-5.57,2.22,2.22,0,0,0,.1-.51h0a2.31,2.31,0,0,0-4.15-1.53h0a2.22,2.22,0,0,0-.26.45,4.61,4.61,0,0,1-5.57,2.57,2.22,2.22,0,0,0-.51-.1h0a2.31,2.31,0,0,0-1.53,4.15h0a2.22,2.22,0,0,0,.45.26,4.61,4.61,0,0,1,2.57,5.57,2.22,2.22,0,0,0-.1.51h0a2.31,2.31,0,0,0,4.15,1.53h0a2.22,2.22,0,0,0,.26-.45A4.61,4.61,0,0,1,325,631.4a2.22,2.22,0,0,0,.51.1h0a2.31,2.31,0,0,0,1.53-4.15h0A2.22,2.22,0,0,0,326.59,627.09Z"
                fill="#fdd835"
                opacity="0.5"
                transform="translate(-189.92 -59.59)"
            />
            <path
                d="M855,127.77a4.61,4.61,0,0,1-2.57-5.57,2.22,2.22,0,0,0,.1-.51h0a2.31,2.31,0,0,0-4.15-1.53h0a2.22,2.22,0,0,0-.26.45,4.61,4.61,0,0,1-5.57,2.57,2.22,2.22,0,0,0-.51-.1h0a2.31,2.31,0,0,0-1.53,4.15h0a2.22,2.22,0,0,0,.45.26,4.61,4.61,0,0,1,2.57,5.57,2.22,2.22,0,0,0-.1.51h0a2.31,2.31,0,0,0,4.15,1.53h0a2.22,2.22,0,0,0,.26-.45,4.61,4.61,0,0,1,5.57-2.57,2.22,2.22,0,0,0,.51.1h0a2.31,2.31,0,0,0,1.53-4.15h0A2.22,2.22,0,0,0,855,127.77Z"
                fill="#fdd835"
                opacity="0.5"
                transform="translate(-189.92 -59.59)"
            />
            <circle
                cx="812.64"
                cy="314.47"
                fill="#f55f44"
                opacity="0.5"
                r="7.53"
            />
            <circle
                cx="230.73"
                cy="746.65"
                fill="#f55f44"
                opacity="0.5"
                r="7.53"
            />
            <circle
                cx="735.31"
                cy="477.23"
                fill="#f55f44"
                opacity="0.5"
                r="7.53"
            />
            <circle
                cx="87.14"
                cy="96.35"
                fill="#4d8af0"
                opacity="0.5"
                r="7.53"
            />
            <circle
                cx="7.53"
                cy="301.76"
                fill="#47e6b1"
                opacity="0.5"
                r="7.53"
            />
        </svg>
    )
}

export const custom = {
    "outline-form"() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <text transform="translate(0.78 -0.73)">
                    <tspan>F</tspan>
                    <tspan
                        x="1.37"
                        y="0">
                        o
                    </tspan>
                    <tspan
                        x="3.02"
                        y="0">
                        r
                    </tspan>
                    <tspan
                        x="4.01"
                        y="0">
                        m
                    </tspan>
                </text>
                <path d="M18,9.26V20H6V4h6.23V2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V9.26Z" />
                <path d="M8.39,16.12l-.5-3.2L16.26,2.05,20,4.91,11.61,15.78Zm1.29-2.74.14.88.89-.09L17.6,5.22l-1-.79Z" />
            </svg>
        )
    },
    "health-law"() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_helserett</title>
                <path d="M13.91,1.91h-8a2,2,0,0,0-2,2v16a2,2,0,0,0,2,2h12a2,2,0,0,0,2-2v-12Zm4,18h-12v-16h8v4h4Z" />
                <path d="M12,9.69a2.68,2.68,0,0,1,1.33.3.55.55,0,0,1,.32.53.52.52,0,0,1-.58.39c-.36,0-.71-.18-1.08-.22s-.83,0-.92.47.28.58.62.7.75.23,1.11.38a1.6,1.6,0,0,1,.84,2.51.75.75,0,0,0-.06.79,1.49,1.49,0,0,1-1.27,2.09,6.1,6.1,0,0,1-1.87-.34.42.42,0,0,1-.32-.58c.08-.31.41-.3.61-.29.41,0,.8.22,1.2.21a.92.92,0,0,0,.71-.41c.17-.37-.13-.59-.48-.71L11,15.14a1.61,1.61,0,0,1-1-2.51.84.84,0,0,0,.07-.84C9.68,10.66,10.4,9.67,12,9.69Zm1,4.05c.05-.42-.33-.48-.53-.57a6.28,6.28,0,0,0-.66-.23c-.47-.17-.75-.11-.92.36s.08.73.49.88l.72.24C12.56,14.58,12.86,14.38,12.91,13.74Z" />
            </svg>
        )
    },
    "lab-medicine"() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="24"
                viewBox="0 0 24 24"
                width="24">
                <g>
                    <rect
                        fill="none"
                        height="24"
                        width="24"
                    />
                </g>
                <g>
                    <path d="M13,11.33L18,18H6l5-6.67V6h2 M15.96,4H8.04C7.62,4,7.39,4.48,7.65,4.81L9,6.5v4.17L3.2,18.4C2.71,19.06,3.18,20,4,20h16 c0.82,0,1.29-0.94,0.8-1.6L15,10.67V6.5l1.35-1.69C16.61,4.48,16.38,4,15.96,4L15.96,4z" />
                </g>
            </svg>
        )
    },
    calculator() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_calculator</title>
                <path d="M21.62,3.87a2.31,2.31,0,0,0-2.49-2.1H4.4A2.32,2.32,0,0,0,1.91,3.87V20.79A2.32,2.32,0,0,0,4.4,22.91H19.13a2.32,2.32,0,0,0,2.49-2.12ZM4.4,20.79V3.87H19.13V20.79Z" />
                <path d="M10.41,18.91h3v-3h-3Zm-4.5,0h3v-3h-3Zm0-4.5h3v-3h-3Zm4.5,0h3v-3h-3Zm4.5,4.5h3v-7.5h-3Z" />
                <rect
                    x="5.91"
                    y="5.91"
                    width="12"
                    height="4"
                />
            </svg>
        )
    },
    form() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_form</title>
                <path d="M3.24,13.15q0-3.83,0-7.67a1.92,1.92,0,0,1,2-2c1.2,0,2.41,0,3.61,0a.49.49,0,0,0,.52-.32,2.76,2.76,0,0,1,2.55-1.65,2.81,2.81,0,0,1,2.69,1.72.36.36,0,0,0,.38.24h3.77a1.93,1.93,0,0,1,2,2q0,7.26,0,14.53a11.78,11.78,0,0,1,0,1.19,1.93,1.93,0,0,1-2,1.79H5.23a1.94,1.94,0,0,1-2-2Q3.25,17,3.24,13.15ZM5.18,5.38,5.2,21l13.63,0,0-15.57H16.86v2.9H7.13V5.38Zm7.79-1a1,1,0,0,0-1-1,1,1,0,0,0-1,1,1,1,0,1,0,2,0Z" />
            </svg>
        )
    },
    nurse() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_nurse</title>
                <path d="M21.49,2.75A5.5,5.5,0,0,0,17.57.91a4.62,4.62,0,0,0-3,1.2L9.11,7.56c-.19.2-.29.29-5.07,8.86L1.55,18.91,1.46,19a2.6,2.6,0,0,0,.27,3.49,2.66,2.66,0,0,0,1.9.82,2.59,2.59,0,0,0,1.59-.54L7.83,20.2c8.55-4.77,8.79-5,9-5.19l5.35-5.35a4.6,4.6,0,0,0,1.09-2.12A5.12,5.12,0,0,0,21.49,2.75Zm-.21,4.38a2.57,2.57,0,0,1-.56,1.13l-5.27,5.28c-.18.14-1.39.9-8.72,5L4,21.24a.7.7,0,0,1-.8-.14A.6.6,0,0,1,3,20.29l2.55-2.56.17-.22C8.8,12,10.4,9.17,10.54,9L16,3.53a2.39,2.39,0,0,1,1.61-.63,3.58,3.58,0,0,1,2.5,1.26A3.3,3.3,0,0,1,21.28,7.13Z" />
                <path d="M17,5.25a2,2,0,0,0-1.42.59l-2,2a2,2,0,0,0,0,2.84,2,2,0,0,0,1.41.58,2,2,0,0,0,1.42-.58l2-2A2,2,0,0,0,17,5.25Z" />
                <circle
                    cx="11.68"
                    cy="12.57"
                    r="0.71"
                />
            </svg>
        )
    },
    dictionary() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_dictionary</title>
                <path d="M21,17.7V2.35A1.17,1.17,0,0,0,19.8,1.18H5.39A2.56,2.56,0,0,0,2.83,3.74V20a2.55,2.55,0,0,0,2.56,2.56H20.27a.7.7,0,0,0,0-1.4h-.46a3.46,3.46,0,0,1,0-2.32A1.16,1.16,0,0,0,21,17.7ZM4.23,3.74a1.14,1.14,0,0,1,.92-1.11V17.49a2.56,2.56,0,0,0-.92.25ZM18.35,21.18h-13a1.16,1.16,0,1,1,0-2.32h13a4.86,4.86,0,0,0,0,2.32Zm1.22-3.72h-13V2.58h13Z" />
                <path d="M10.59,12.88l-.35-1.41H8.47l-.35,1.41H7L8.62,7.13h1.5l1.64,5.75ZM9.6,9.2l-.19-1H9.28l-.18,1-.34,1.32H10Z" />
                <path d="M12.17,10.92V9.81h2.22v1.11Z" />
                <path d="M18.81,12.88H14.89v-1l2.5-3.68H15v-1h3.73v1l-2.51,3.68h2.57Z" />
            </svg>
        )
    },
    radiology() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>HB2.0 radiologi-23</title>
                <path d="M8.51,12.91l-.3,0a1.8,1.8,0,0,1-1.32-.94,2.26,2.26,0,0,1,.25-1.76,3,3,0,0,0,.31-1,3.7,3.7,0,0,0-1.2-1.81A5.74,5.74,0,0,0,4.06,5.82h0V5.07h0c.76,0,2.11,1.14,2.72,1.74A4.08,4.08,0,0,1,8.2,9.15a3.68,3.68,0,0,1-.36,1.32c-.18.43-.38.91-.27,1.14a1,1,0,0,0,.76.53A.8.8,0,0,0,9.05,12a4.49,4.49,0,0,0,.56-.79,2,2,0,0,1,2.13-.93c.58.13,1,.08,1.18-.14a1.15,1.15,0,0,0,0-1.61A4,4,0,0,0,11,7.65,7.65,7.65,0,0,1,7.72,5.71l.52-.53A7,7,0,0,0,11.09,6.9a4.81,4.81,0,0,1,2.35,1,1.89,1.89,0,0,1,0,2.65,1.86,1.86,0,0,1-1.88.36,1.22,1.22,0,0,0-1.32.58,5.62,5.62,0,0,1-.68.94A1.5,1.5,0,0,1,8.51,12.91Z" />
                <path d="M14.19,18.57c0-.53-.21-3.26.12-4a2,2,0,0,1,1-.84c.42-.22.53-.31.53-.52,0-.73-.49-1.95-1-1.95a1.48,1.48,0,0,0-.69.29,5.34,5.34,0,0,1-.8.41,2.86,2.86,0,0,1-1.82.05.47.47,0,0,0-.42.1,1.46,1.46,0,0,0-.46,1.1c0,.19.12.28.52.48a1.72,1.72,0,0,1,1,.92,14.86,14.86,0,0,1,.26,3.91l-.75,0a14,14,0,0,0-.21-3.62c-.08-.22-.29-.33-.62-.5s-.93-.47-.93-1.15a2.2,2.2,0,0,1,.75-1.69,1.2,1.2,0,0,1,1.06-.23,2.14,2.14,0,0,0,1.36,0,5.57,5.57,0,0,0,.7-.37,2.08,2.08,0,0,1,1.07-.39c1.15,0,1.76,1.76,1.76,2.7,0,.69-.54,1-.93,1.18A1.31,1.31,0,0,0,15,15a14.05,14.05,0,0,0-.05,3.57Z" />
                <path d="M13.32,18.55h-.75a12.59,12.59,0,0,1,.09-1.5c.08-.86.1-1.26-.22-1.61a3.61,3.61,0,0,0-1.22-.79l.26-.7a4.39,4.39,0,0,1,1.52,1,2.56,2.56,0,0,1,.4,2.19A13.86,13.86,0,0,0,13.32,18.55Z" />
                <path d="M20.94,19.76H2.87a2,2,0,0,1-2-2V6.19a2,2,0,0,1,2-2H20.94a2,2,0,0,1,2,2V17.8A2,2,0,0,1,20.94,19.76ZM2.87,5.91a.29.29,0,0,0-.28.28V17.8a.29.29,0,0,0,.28.28H20.94a.29.29,0,0,0,.28-.28V6.19a.29.29,0,0,0-.28-.28Z" />
            </svg>
        )
    },
    examination() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="24"
                viewBox="0 0 24 24"
                width="24">
                <g>
                    <rect
                        fill="none"
                        height="24"
                        width="24"
                    />
                </g>
                <g>
                    <path d="M7,19c-1.1,0-2,0.9-2,2h14c0-1.1-0.9-2-2-2h-4v-2h3c1.1,0,2-0.9,2-2h-8c-1.66,0-3-1.34-3-3c0-1.09,0.59-2.04,1.47-2.57 c0.41,0.59,1.06,1,1.83,1.06c0.7,0.06,1.36-0.19,1.85-0.62l0.59,1.61l0.94-0.34l0.34,0.94l1.88-0.68l-0.34-0.94l0.94-0.34 L13.76,2.6l-0.94,0.34L12.48,2L10.6,2.68l0.34,0.94L10,3.97l0.56,1.55C9.39,5.48,8.37,6.27,8.08,7.38C6.27,8.14,5,9.92,5,12 c0,2.76,2.24,5,5,5v2H7z M12.86,4.52l1.71,4.7l-0.94,0.34l-1.71-4.7L12.86,4.52z M10.5,7c0.55,0,1,0.45,1,1c0,0.55-0.45,1-1,1 c-0.55,0-1-0.45-1-1C9.5,7.45,9.95,7,10.5,7z" />
                </g>
            </svg>
        )
    },
    drugs() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_drugs</title>
                <path d="M23.23,11.88A3.33,3.33,0,0,0,21.5,9.33a7.3,7.3,0,0,0-3.76-.93,8.1,8.1,0,0,0-1.4.13A4,4,0,0,0,12.78,6.3h-8a4,4,0,0,0,0,8H6.16a2.81,2.81,0,0,0-.52,1.47v1.46A3.17,3.17,0,0,0,7.4,19.82a7.55,7.55,0,0,0,3.79.94,7.43,7.43,0,0,0,3.65-.91,3.15,3.15,0,0,0,1.79-2.64V16.9a7.35,7.35,0,0,0,1.06.07A7.43,7.43,0,0,0,21.47,16a3.12,3.12,0,0,0,1.77-2.63ZM9.64,8h3.14a2.27,2.27,0,0,1,1.89,1,5.88,5.88,0,0,0-.62.29,3.15,3.15,0,0,0-1.8,2.81v.15a10,10,0,0,0-1.11-.07,8.12,8.12,0,0,0-1.5.14ZM4.79,12.55A2.28,2.28,0,1,1,4.79,8H7.94v4.55ZM14,18.36a5.75,5.75,0,0,1-2.82.7,6,6,0,0,1-2.88-.67c-.36-.24-1-.7-1-1.2V15.78c0-.16,0-.64,1-1.24a5.63,5.63,0,0,1,2.84-.65,5.81,5.81,0,0,1,2.85.68,1.48,1.48,0,0,1,.93,1.33v1.31C14.93,17.62,14.59,18,14,18.36Zm6.55-3.76a6.76,6.76,0,0,1-4,.55,3.31,3.31,0,0,0-1.7-2.07h0a7.85,7.85,0,0,0-.88-.4V12.1a1.57,1.57,0,0,1,.93-1.32,6,6,0,0,1,2.86-.68,5.69,5.69,0,0,1,2.83.65c.92.6,1,1.09,1,1.19V13.4C21.54,13.9,20.93,14.37,20.56,14.6Z" />
                <path d="M17.94,10.7,16,12a.85.85,0,0,0,.46,1.57.92.92,0,0,0,.47-.14l2-1.28a.85.85,0,1,0-.93-1.42Z" />
                <path d="M13.06,15.8l-2.27-1.15A.85.85,0,0,0,10,16.16l2.27,1.16A.85.85,0,0,0,13.43,17,.86.86,0,0,0,13.06,15.8Z" />
            </svg>
        )
    },
    "package-process"() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24">
                <path
                    d="M0 0h24v24H0V0z"
                    fill="none"
                />
                <path d="M20 7h-4V5l-2-2h-4L8 5v2H4c-1.1 0-2 .9-2 2v5c0 .75.4 1.38 1 1.73V19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-3.28c.59-.35 1-.99 1-1.72V9c0-1.1-.9-2-2-2zM10 5h4v2h-4V5zM4 9h16v5h-5v-3H9v3H4V9zm9 6h-2v-2h2v2zm6 4H5v-3h4v1h6v-1h4v3z" />
            </svg>
        )
    },
    "important-update"() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_imp_update</title>
                <path d="M22.91,9.36V1L19.73,4.12a10.82,10.82,0,1,0-7.64,18.47A10.83,10.83,0,0,0,22.91,11.77H20.5A8.42,8.42,0,1,1,18,5.82L14.49,9.36Z" />
                <path d="M10.77,16.66c0,.7.39,1.16,1.37,1.16s1.38-.46,1.38-1.16v-.5c0-.69-.4-1.16-1.38-1.16s-1.37.47-1.37,1.16Z" />
                <polygon points="12.67 14.06 13.35 9.29 13.35 6.06 10.93 6.06 10.93 9.29 11.63 14.06 12.67 14.06" />
            </svg>
        )
    },
    "relevant-documents"() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_rel_docu</title>
                <path d="M22.55,15.41a4.24,4.24,0,0,0-2.63-1.71L20,9.21a4.12,4.12,0,0,0,1.52-.66,4.24,4.24,0,1,0-6.63-3.06h0L12.26,6.83a6.57,6.57,0,0,0-10.7,7.62,6.57,6.57,0,0,0,9.15,1.61,6.47,6.47,0,0,0,1-.9l3.08,2.1.05,0A4.24,4.24,0,0,0,18.33,22a4.74,4.74,0,0,0,.75.06,4.17,4.17,0,0,0,2.42-.77,4.24,4.24,0,0,0,1.05-5.91ZM17.79,3.24a2.25,2.25,0,1,1-.55,3.12A2.25,2.25,0,0,1,17.79,3.24ZM9.56,14.42A4.57,4.57,0,1,1,6.93,6.11a4.43,4.43,0,0,1,.81.07,4.56,4.56,0,0,1,1.82,8.24Zm6.08,1L12.87,13.5a6.57,6.57,0,0,0,.31-4.89L15.54,7.4a.6.6,0,0,0,.06.11A4.18,4.18,0,0,0,18,9.16l-.06,4.62a4.24,4.24,0,0,0-1.28.59A4,4,0,0,0,15.64,15.39Zm5.64,2.85a2.19,2.19,0,0,1-.92,1.44,2.24,2.24,0,1,1,.92-1.44Z" />
            </svg>
        )
    },
    "question-form"() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_sporreskjema</title>
                <path d="M3.22,13.12V5.45a1.92,1.92,0,0,1,2-2.05h3.6a.47.47,0,0,0,.52-.32,2.76,2.76,0,0,1,2.56-1.64,2.79,2.79,0,0,1,2.69,1.72.36.36,0,0,0,.38.24h3.76a1.93,1.93,0,0,1,2,2c0,4.84,0,9.69,0,14.53a11.81,11.81,0,0,1,0,1.19,1.94,1.94,0,0,1-2,1.79c-1.83,0-3.66,0-5.49,0h-8a1.92,1.92,0,0,1-2-2ZM5.16,5.35V20.93H18.79V5.35H16.84v2.9H7.11V5.35Zm7.79-1a1,1,0,0,0-1-1,1,1,0,0,0-1,1,1,1,0,0,0,1,1A1,1,0,0,0,13,4.36Z" />
                <path d="M11.22,16.09V14.14c1.24-.05,1.79-.39,1.79-1.35v-.28c0-.67-.32-1-.89-1S11.18,12,11,12.65L9.6,12a2.46,2.46,0,0,1,2.58-2c1.8,0,2.7.89,2.7,2.52a2.42,2.42,0,0,1-2,2.6v1ZM11,18.27v-.38c0-.53.3-.88,1.05-.88s1.05.35,1.05.88v.38c0,.54-.3.89-1.05.89S11,18.81,11,18.27Z" />
            </svg>
        )
    },
    disease() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_disease</title>
                <path d="M20,6H16.6V5.57a3.06,3.06,0,0,0-3.06-3.05H10.46A3.06,3.06,0,0,0,7.4,5.57V6H4A2,2,0,0,0,2,8V19a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6ZM9.4,5.57a1.05,1.05,0,0,1,1.06-1.05h3.08A1.05,1.05,0,0,1,14.6,5.57v.08H9.4ZM20,19H4V8H20Z" />
                <polygon points="11.22 15.94 12.98 15.94 12.98 14.02 14.97 14.02 14.97 12.25 12.98 12.25 12.98 10.16 11.22 10.16 11.22 12.25 9.2 12.25 9.2 14.02 11.22 14.02 11.22 15.94" />
            </svg>
        )
    },
    symptom() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_symptom</title>
                <path d="M11.43,11.7A4.31,4.31,0,0,1,18,8a29.31,29.31,0,0,0,3.13-.66l-.54-2.16a36.66,36.66,0,0,1-9.19,1.08,36.73,36.73,0,0,1-9.2-1.08L1.69,7.37A38.23,38.23,0,0,0,8.18,8.45V22.51h2.16V16h2.17v6.49h2.16V15.87A4.31,4.31,0,0,1,11.43,11.7Z" />
                <circle
                    cx="11.43"
                    cy="3.05"
                    r="2.16"
                />
                <path d="M18.74,13.42a3.21,3.21,0,0,0,.47-1.66,3.3,3.3,0,1,0-3.3,3.3,3.38,3.38,0,0,0,1.67-.47l3.14,3.14,1.16-1.16Zm-2.83,0a1.65,1.65,0,1,1,1.65-1.65A1.66,1.66,0,0,1,15.91,13.41Z" />
            </svg>
        )
    },
    "patient-information"() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_pasientinfo</title>
                <path d="M19.12,2.12h-14a2,2,0,0,0-2,2v14a2,2,0,0,0,2,2h4l3,3,3-3h4a2,2,0,0,0,2-2v-14A2,2,0,0,0,19.12,2.12Zm0,16H14.29l-.59.59-1.58,1.58L10.53,18.7,10,18.12H5.12v-14h14Zm-7.12-7a2.5,2.5,0,1,0-2.5-2.5A2.51,2.51,0,0,0,12,11.12Zm5,3.81c0-2.08-3.31-3-5-3s-5,.9-5,3v1.19H17Z" />
            </svg>
        )
    },
    "h-dir"() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <title>Icon_HDIR</title>
                <path d="M23,12.76A2.64,2.64,0,0,1,23,13a6.94,6.94,0,0,1-.67,2.57,7,7,0,0,1-1.47,2A7.06,7.06,0,0,1,9,13.61a6.82,6.82,0,0,1,0-1.86c0-.27.08-.53.12-.81l.07,0c.4.21.8.42,1.19.64a8.25,8.25,0,0,1,1.35.92,4.16,4.16,0,0,1,.94,1.19c.35.66.64,1.35.93,2,.14.33.27.67.42,1a1.28,1.28,0,0,0,.14.23.32.32,0,0,0,.36.12l.34-.14a.32.32,0,0,0,.17-.34,3.07,3.07,0,0,0-.14-.52c-.18-.55-.37-1.1-.54-1.65a4.13,4.13,0,0,1-.19-1.15,2.69,2.69,0,0,1,.15-1.13,1.39,1.39,0,0,1,.23-.41.49.49,0,0,1,.57-.19l1.12.34,2,.61a.93.93,0,0,0,.37.06.23.23,0,0,0,.21-.12A1.1,1.1,0,0,0,18.9,12a.28.28,0,0,0-.14-.27,1.4,1.4,0,0,0-.3-.15l-2.41-1a4.67,4.67,0,0,1-.83-.43,1.82,1.82,0,0,1-.82-1.25c-.05-.3-.08-.59-.11-.89s0-.64-.07-1,0-.7-.05-1a1.7,1.7,0,0,0,0-.22s0,0,.05,0c.26,0,.53-.12.8-.16a5.49,5.49,0,0,1,1-.06,6.87,6.87,0,0,1,3.81,1.14,7,7,0,0,1,2.81,3.58,6.73,6.73,0,0,1,.35,1.6c0,.16,0,.32,0,.49v.43ZM16.63,7.54a.68.68,0,0,0-.5.2l-.47.46a.69.69,0,0,0-.2.59.66.66,0,0,0,.46.55.63.63,0,0,0,.69-.15l.28-.28a3.2,3.2,0,0,0,.25-.27.62.62,0,0,0,.07-.7A.66.66,0,0,0,16.63,7.54Z" />
                <path d="M6.63,5l.26.08A.7.7,0,0,1,7.21,6c-.1.22-.2.43-.31.65a.71.71,0,0,1-1.35-.35A.78.78,0,0,1,5.64,6l.27-.55A.68.68,0,0,1,6.44,5h.19Z" />
                <path d="M9.47,9.78l-.38,1.15h0L7.5,10.14a.7.7,0,0,0-.4-.08.45.45,0,0,0-.38.41,3.23,3.23,0,0,0,0,.44c0,.76.06,1.52.1,2.29s0,1.43.08,2.15c0,.32,0,.64,0,1a.83.83,0,0,1,0,.22.3.3,0,0,1-.23.24,2.87,2.87,0,0,1-.41.05.29.29,0,0,1-.3-.2,2.77,2.77,0,0,1-.12-.41l-1-4.38L4.17,9.22a1.8,1.8,0,0,0-.34-.66,4,4,0,0,0-.93-.85l-1.56-1a2.11,2.11,0,0,1-.23-.19A.31.31,0,0,1,1,6.12l.21-.35a.28.28,0,0,1,.31-.11A1.63,1.63,0,0,1,2,5.84L6,7.93,8.5,9.24l1,.53Z" />
                <path d="M13.14,6.1v.44c0,.15,0,.3,0,.45v.69c0,.5,0,1,0,1.49a3,3,0,0,1-.35,1.37,1.71,1.71,0,0,1-.21.26.58.58,0,0,1-.66.12l-1-.46L9.48,9.79A7.16,7.16,0,0,1,13.14,6.1Z" />
            </svg>
        )
    },
    null() {
        return (
            <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            />
        )
    },
    regional() {
        return (
            <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <circle
                    cx="12"
                    cy="12"
                    r="2.5"
                />
                <circle
                    cx="4.5"
                    cy="12"
                    r="2.5"
                />
                <circle
                    cx="19.5"
                    cy="12"
                    r="2.5"
                />
                <circle
                    cx="12"
                    cy="19.5"
                    r="2.5"
                />
                <circle
                    cx="12"
                    cy="4.5"
                    r="2.5"
                />
            </svg>
        )
    },
    "contact-page"() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="24"
                viewBox="0 0 24 24"
                width="24">
                <rect
                    fill="none"
                    height="24"
                    width="24"
                />
                <path d="M13.17,4L18,8.83V20H6V4H13.17 M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2L14,2z M12,14 c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C10,13.1,10.9,14,12,14z M16,17.43c0-0.81-0.48-1.53-1.22-1.85 C13.93,15.21,12.99,15,12,15c-0.99,0-1.93,0.21-2.78,0.58C8.48,15.9,8,16.62,8,17.43V18h8V17.43z" />
            </svg>
        )
    },
    rule() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                enableBackground="new 0 0 24 24"
                height="24"
                viewBox="0 0 24 24"
                width="24">
                <g>
                    <rect
                        fill="none"
                        height="24"
                        width="24"
                    />
                    <path d="M16.54,11L13,7.46l1.41-1.41l2.12,2.12l4.24-4.24l1.41,1.41L16.54,11z M11,7H2v2h9V7z M21,13.41L19.59,12L17,14.59 L14.41,12L13,13.41L15.59,16L13,18.59L14.41,20L17,17.41L19.59,20L21,18.59L18.41,16L21,13.41z M11,15H2v2h9V15z" />
                </g>
            </svg>
        )
    },
    rki() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <path d="M5.73,8.69a12.26,12.26,0,0,1,1.83-.13,3.1,3.1,0,0,1,2,.48,1.54,1.54,0,0,1,.56,1.27A1.61,1.61,0,0,1,9,11.8v0A1.55,1.55,0,0,1,9.82,13a14.72,14.72,0,0,0,.49,1.71H8.91a7.16,7.16,0,0,1-.43-1.41c-.16-.76-.42-1-1-1H7.09v2.38H5.73ZM7.09,11.3h.54c.68,0,1.09-.34,1.09-.88s-.38-.83-1-.84a4.8,4.8,0,0,0-.62,0Z" />
                <path d="M11.33,8.61h1.36v2.68h0c.14-.24.28-.45.41-.67l1.38-2H16.2l-2,2.58,2.11,3.48H14.7l-1.48-2.62-.53.64v2H11.33Z" />
                <path d="M18.51,8.61v6.06H17.13V8.61Z" />
                <path d="M12.13,3H19.8a1.93,1.93,0,0,1,2.05,2c0,1.21,0,12.26,0,13.52a1.92,1.92,0,0,1-2,2l-14.53,0a11.81,11.81,0,0,1-1.19,0,1.94,1.94,0,0,1-1.79-2c0-1.83,0-3.66,0-5.49q0-4,0-8a1.94,1.94,0,0,1,2-2ZM19.9,4.92l-15.58,0q0,6.83,0,13.63l15.57,0" />
            </svg>
        )
    },
    "no-data": NoDataIcon
} as const

export const google = {
    "light-bulb"() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px">
                <path
                    d="M0 0h24v24H0z"
                    fill="none"
                />
                <path d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z" />
            </svg>
        )
    },
    "table-view"() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                // enable-background="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px">
                <g>
                    <rect
                        fill="none"
                        height="24"
                        width="24"
                    />
                    <path d="M19,7H9C7.9,7,7,7.9,7,9v10c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V9C21,7.9,20.1,7,19,7z M19,9v2H9V9H19z M13,15v-2h2v2H13z M15,17v2h-2v-2H15z M11,15H9v-2h2V15z M17,13h2v2h-2V13z M9,17h2v2H9V17z M17,19v-2h2v2H17z M6,17H5c-1.1,0-2-0.9-2-2V5 c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2v1h-2V5H5v10h1V17z" />
                </g>
            </svg>
        )
    }
} as const

export const react = {
    alarm() {
        return (
            <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path
                        fill="none"
                        d="M0 0h24v24H0z"
                    />
                    <path d="M4 20v-6a8 8 0 1 1 16 0v6h1v2H3v-2h1zm2 0h12v-6a6 6 0 1 0-12 0v6zm5-18h2v3h-2V2zm8.778 2.808l1.414 1.414-2.12 2.121-1.415-1.414 2.121-2.121zM2.808 6.222l1.414-1.414 2.121 2.12L4.93 8.344 2.808 6.222zM7 14a5 5 0 0 1 5-5v2a3 3 0 0 0-3 3H7z" />
                </g>
            </svg>
        )
    },
    syringe() {
        return (
            <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path
                        fill="none"
                        d="M0 0H24V24H0z"
                    />
                    <path d="M21.678 7.98l-1.415 1.413-2.12-2.12-2.122 2.12 3.535 3.536-1.414 1.414-.707-.707L11.071 20H5.414l-2.121 2.121-1.414-1.414L4 18.586v-5.657l6.364-6.364-.707-.707 1.414-1.414 3.536 3.535 2.12-2.121-2.12-2.121 1.414-1.415 5.657 5.657zm-5.657 4.242l-4.243-4.243-1.414 1.414 2.121 2.122-1.414 1.414-2.121-2.121-1.414 1.414 2.12 2.121-1.413 1.414-2.122-2.121-.121.121V18h4.243l5.778-5.778z" />
                </g>
            </svg>
        )
    }
} as const

export interface CustomIconProps extends SvgIconProps {
    name: keyof typeof react | keyof typeof google | keyof typeof custom
}

const customIcons = {
    ...custom,
    ...react,
    ...google
}

export default function CustomIcon({ name, ...props }: CustomIconProps) {
    const CustomIconComponent = customIcons[name]
    return (
        <SvgIcon {...props}>
            <CustomIconComponent />
        </SvgIcon>
    )
}
