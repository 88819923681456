import * as React from "react"

import Button from "@mui/material/Button"

import { PropsInButtonNotCollidingWithLinkProps as ButtonLinkUnionProps } from "./Link"

type ButtonLinkProps = ButtonLinkUnionProps & { ref?: any }

const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
    function ButtonLink(props, ref) {
        return (
            <Button
                {...props}
                ref={ref}
                component="a"
                sx={{
                    textTransform: "none",
                    "&:hover": {
                        textDecoration: "none"
                    }
                }}
            />
        )
    }
)

export default ButtonLink
