import { externalDialog } from "config/config"
import { t } from "locales"
import * as React from "react"

import Button from "@mui/material/Button"
import { ButtonProps as MuiButtonProps } from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControlLabel from "@mui/material/FormControlLabel"
import MuiLink from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { isLocalStorageSupported } from "@nhi/utils"
import { extractHostName } from "lib/url"

interface ExternalDialogProps {
    onClose: MuiButtonProps["onClick"]
    href: string
}

/**
 * Get translated content of external link
 * @param url url of external link
 * @returns translated content of external link
 */
function getContent(url: string) {
    const key = extractHostName(url)

    if (!externalDialog) return ""

    const external = externalDialog as any

    if (external[key]) return external[key]

    return externalDialog.default
}

export default function ExternalDialog({ onClose, href }: ExternalDialogProps) {
    const handleChange = (
        _: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        if (isLocalStorageSupported()) {
            localStorage.setItem(
                "external-link-popup-disabled",
                checked.toString()
            )
        }
    }

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation()
    }

    return (
        <Dialog
            open
            aria-labelledby="draggable-dialog-title"
            onClose={onClose}
            onClick={handleClick}>
            <DialogTitle
                sx={{ fontSize: "h3.fontSize" }}
                id="draggable-dialog-title">
                {t["external-link"]["title"]}
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">{getContent(href)}</Typography>
                <StyledLink
                    href={href}
                    rel="noopener noreferrer"
                    target="_blank">
                    {href}
                </StyledLink>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <FormControlLabel
                    sx={{ fontSize: "body2.fontSize" }}
                    control={
                        <Checkbox
                            color="primary"
                            name="external"
                            onChange={handleChange}
                        />
                    }
                    label={t["external-link"]["checkbox-label"]}
                />
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={onClose}>
                    {t["external-link"]["close"]}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const StyledLink = styled(MuiLink)(({ theme }) => ({
    display: "block",
    marginTop: theme.spacing(2),
    wordBreak: "break-all",
    color: theme.palette.link.light
}))
