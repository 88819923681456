import { SvgIconProps } from "@mui/material/SvgIcon"

import CustomIcon, {
    CustomIconProps,
    custom,
    google,
    react
} from "./icons/custom"
import { mui } from "./icons/mui"

export const icons = {
    mui,
    custom,
    react,
    google
}

const customIcons = { ...custom, ...react, ...google }

export type IconType = keyof typeof mui | CustomIconProps["name"] | ""

export interface IconProps extends SvgIconProps {
    name: IconType | string
}

export default function Icon({ name, ...props }: IconProps) {
    if (name in customIcons) {
        return (
            <CustomIcon
                name={name as keyof typeof customIcons}
                {...props}
            />
        )
    }

    //@ts-ignore
    const MuiIcon = mui[name] ?? mui.default
    return <MuiIcon {...props} />
}
