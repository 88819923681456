/* eslint-disable jsx-a11y/anchor-has-content */
import Icon from "components/Icon"
import { general } from "config/config"
import * as React from "react"

import { ButtonProps as MuiButtonProps } from "@mui/material/Button"
import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link"
import { styled } from "@mui/material/styles"

import { isLocalStorageSupported } from "@nhi/utils"

import ButtonLink from "./ButtonLink"
import ExternalDialog from "./ExternalDialog"
import type { LinkOrButtonProps } from "./Link"

type ExternalProps = Omit<LinkOrButtonProps, "button"> & {
    href: string
    button?: boolean
    noWrap?: boolean
}

/**
 * Regular expression test
 * to check if a url should be accepted as safe
 * @see https://regex101.com/r/5dT88w/3
 */
export function isSafeExternal(href: string) {
    const protocol = "https?:\\/\\/"
    const subdomain = "\\w+\\."
    const urls = general.safeExternalURLs
        .replace(/\./g, "\\.")
        .split(",")
        .join("|")
    const path = "[?#/].*"
    const re = new RegExp(`^(${protocol})?(${subdomain})*?(${urls})(${path})?$`)
    return re.test(href)
}

export default function External(
    props: React.PropsWithChildren<ExternalProps>
) {
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const handleClose = () => setDialogOpen(false)
    const [ignoreWarning, setIgnorewarning] = React.useState(false)

    React.useEffect(() => {
        if (!isLocalStorageSupported()) {
            return
        }
        setIgnorewarning(
            localStorage.getItem("external-link-popup-disabled") === "true"
        )
    }, [dialogOpen])

    const handleClick = React.useCallback(
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (ignoreWarning) {
                return
            }

            event.preventDefault()
            setDialogOpen(true)
        },
        [ignoreWarning]
    )

    return (
        <>
            <SafeExternalLink
                {...props}
                onClick={handleClick}
            />
            {dialogOpen ? (
                <ExternalDialog
                    href={props.href}
                    onClose={handleClose}
                />
            ) : null}
        </>
    )
}

const externalIcon = (
    <Icon
        name="external-links"
        color="inherit"
        style={{ height: 14, width: 14 }}
    />
)

const common = {
    rel: "noreferrer noopener",
    target: "_blank"
}

export const SafeExternalLink: React.FC<ExternalProps> = ({
    button,
    noWrap = false,
    ...props
}) => {
    if (button) {
        return (
            <ButtonLink
                {...common}
                {...(props as MuiButtonProps<"a">)}
                endIcon={externalIcon}
            />
        )
    }

    return (
        <MuiLink
            {...common}
            {...(props as MuiLinkProps)}
            underline="hover"
            noWrap={noWrap}>
            <WrappedText nowrap={noWrap.toString()}>
                {props.children}&nbsp;
            </WrappedText>
            {externalIcon}
        </MuiLink>
    )
}

const WrappedText = styled("span")<{ nowrap: string }>(({ nowrap }) => ({
    ...(nowrap === "true" && {
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "calc(100% - 14px)",
        verticalAlign: "top",
        "&:hover": {
            textDecoration: "underline"
        }
    })
}))
