import AppsIcon from "@mui/icons-material/AppsOutlined"
import ToolIcon from "@mui/icons-material/AppsOutlined"
import ArrowDropDown from "@mui/icons-material/ArrowDropDown"
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined"
import ChecklistIcon from "@mui/icons-material/AssignmentTurnedInOutlined"
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined"
import FeedbackIcon from "@mui/icons-material/ChatOutlined"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import ClassIcon from "@mui/icons-material/ClassOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined"
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined"
import ClinicalChaptersIcon from "@mui/icons-material/CreateNewFolderOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutline"
import DefaultIcon from "@mui/icons-material/Dvr"
import EventIcon from "@mui/icons-material/Event"
import NationalGuidelinesIcon from "@mui/icons-material/FlagOutlined"
import LibraryBooksIcon from "@mui/icons-material/LibraryBooksOutlined"
import LinkIcon from "@mui/icons-material/Link"
import HospitalIcon from "@mui/icons-material/LocalHospitalOutlined"
import VideoIcon from "@mui/icons-material/OndemandVideoOutlined"
import MediaIcon from "@mui/icons-material/OndemandVideoOutlined"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import PanToolIcon from "@mui/icons-material/PanToolOutlined"
import PrintIcon from "@mui/icons-material/PrintOutlined"
import AddIcon from "@mui/icons-material/QueueOutlined"
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined"
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined"
import CourseIcon from "@mui/icons-material/SchoolOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import QRCodeIcon from "@mui/icons-material/Share"
import TimeSort from "@mui/icons-material/Sort"
import ABCSort from "@mui/icons-material/SortByAlpha"
import FavoritesIcon from "@mui/icons-material/StarBorderSharp"
import FavoriteRemovedIcon from "@mui/icons-material/StarBorderSharp"
import FavoriteAddedIcon from "@mui/icons-material/StarSharp"
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined"
import TocOutlinedIcon from "@mui/icons-material/TocOutlined"
import ContactIcon from "@mui/icons-material/TouchAppOutlined"
import UpdateIcon from "@mui/icons-material/Update"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"

export const mui = {
    print: PrintIcon,
    qr: QRCodeIcon,
    "favorite-added": FavoriteAddedIcon,
    "favorite-removed": FavoriteRemovedIcon,
    update: UpdateIcon,
    apps: AppsIcon,
    video: VideoIcon,
    media: MediaIcon,
    add: AddIcon,
    course: CourseIcon,
    link: LinkIcon,
    contact: ContactIcon,
    desktop: DefaultIcon,
    event: EventIcon,
    emergency: HospitalIcon,
    class: ClassIcon,
    visibility: VisibilityOutlinedIcon,
    checklist: ChecklistIcon,
    "clinical-procedures": PanToolIcon,
    "clinical-chapters": ClinicalChaptersIcon,
    "external-links": OpenInNewIcon,
    "library-books": LibraryBooksIcon,
    "national-guidelines": NationalGuidelinesIcon,
    tool: ToolIcon,
    navigation: TocOutlinedIcon,
    default: DefaultIcon,
    "room-outlined": RoomOutlinedIcon,
    abcSort: ABCSort,
    timeSort: TimeSort,
    categorySort: CategoryOutlinedIcon,
    delete: DeleteIcon,
    favorite: FavoritesIcon,
    feedback: FeedbackIcon,
    settings: SettingsOutlinedIcon,
    close: CloseOutlinedIcon,
    reportProblem: ReportProblemOutlinedIcon,
    storage: StorageOutlinedIcon,
    arrowForwardIos: ArrowForwardIosOutlinedIcon,
    contentCopy: ContentCopyOutlinedIcon,
    check: CheckOutlinedIcon,
    code: CodeOutlinedIcon,
    "arrow-dropdown": ArrowDropDown
} as const
